import axios from "axios";
import { BASE_URL_V2 } from "../../config/config";
/* eslint-disable camelcase */

class productNewsService {
  getAllPosts = (data) => {
    const { page = 0, size = 10, sortBy = {} } = data || {};
    console.log("sortBy", sortBy);
    return new Promise((resolve, reject) => {
      axios
        .post(
          BASE_URL_V2("user-management") +
            "posts/all?page=" +
            page +
            "&size=" +
            size,
          sortBy,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  create = (data) => {
    const { postTitle, body, comment, subHeaders, validFrom, validTo } =
      data || {};
    const apiPayload = {
      header: postTitle,
      body: body,
      comment: comment,
      subHeader1: subHeaders[0]?.header || null,
      subBody1: subHeaders[0]?.body || null,
      subHeader2: subHeaders[1]?.header || null,
      subBody2: subHeaders[1]?.body || null,
      subHeader3: subHeaders[2]?.header || null,
      subBody3: subHeaders[2]?.body || null,
      validFrom: validFrom ? new Date(validFrom).getTime() : null,
      validUntil: validTo ? new Date(validTo).getTime() : null,
    };
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_V2("user-management") + "posts/create", apiPayload)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  update = (id, data) => {
    const { postTitle, body, comment, subHeaders, validFrom, validTo } =
      data || {};
    const apiPayload = {
      header: postTitle,
      body: body,
      comment: comment,
      subHeader1: subHeaders[0]?.header || null,
      subBody1: subHeaders[0]?.body || null,
      subHeader2: subHeaders[1]?.header || null,
      subBody2: subHeaders[1]?.body || null,
      subHeader3: subHeaders[2]?.header || null,
      subBody3: subHeaders[2]?.body || null,
      validFrom: validFrom ? new Date(validFrom).getTime() : null,
      validUntil: validTo ? new Date(validTo).getTime() : null,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL_V2("user-management") + "posts/update?id=" + id,
          apiPayload,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  deletePost = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(BASE_URL_V2("user-management") + "posts/delete?id=" + id)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
}

const instance = new productNewsService();

export default instance;
