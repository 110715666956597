import React from "react";
import { Grid, Typography } from "@mui/material";
import { renderValueAttribute } from "../../../common/Helper/renderValue";

const AttributeDetails = ({ data, title }) => {
  return (
    <Grid container spacing={2}>
      {Boolean(title) && (
        <Grid item>
          <Typography variant="subTitle1" component="h2">
            {title}
          </Typography>
        </Grid>
      )}

      {data &&
        Object.keys(data)
          // .sort((a, b) => {
          //   if (a < b) {
          //     return -1;
          //   }
          //   if (a > b) {
          //     return 1;
          //   }
          //   return 0;
          // })
          .map((m) => {
            if (m !== "_id") {
              return (
                <Grid
                  item
                  xs={12}
                  lg={data[m] && data[m].length > 100 ? 12 : 6}
                  key={m}>
                  <strong>{m}:</strong> {renderValueAttribute(data[m], m)}
                </Grid>
              );
            } else {
              return null;
            }
          })}
    </Grid>
  );
};

export default AttributeDetails;
