import React, { useState, useEffect } from "react";
import { styles } from "../../../styles/Dashboard/data";
import { styles as dataTableStyles } from "../../../styles/dataTable";
import { withMultipleStyles } from "../../../styles/withMultipleStyles";

// redux
import { useSelector, useDispatch } from "react-redux";
import {
  getAllPosts,
  setSelected,
  getById,
  deletePost,
} from "../../../store/ProductNews/productNewsSlice";
import { showMessage } from "../../../store/Revalu/notificationSlice";

//Components
import ActionButtons from "./actionButtons";
import Search from "../EDPManagement/Search";
import TableActionButton from "./tableActionButton";
import CreateProductNewsForm from "./createProductNewsForm";
import AttributeDetails from "./attributeDetails";

import {
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DialogHeader } from "../Common/dialogHeader";
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";

const ProductNews = (props) => {
  const { classes } = props;
  const [page, setPageNo] = useState(0);
  const [size, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [selectedIdToView, setSelectedIdToView] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const dispatch = useDispatch();
  const { data, loading } = useSelector(({ productNews }) => productNews.slice);
  const handleSelect = (id) => {
    dispatch(setSelected({ id }));
    setSelectedId(id);
    setShowAddModal(true);
  };
  const handleViewDetails = (id) => {
    setSelectedIdToView(id);
    setSelectedId(id);
  };
  const handleDelete = async (id) => {
    try {
      setSelectedId(id);
      console.log("id", id);
      const res = await dispatch(deletePost(id));
      console.log("res", res);
      if (res) {
        dispatch(showMessage({ message: "Record deleted" }));
        await dispatch(getAllPosts({ page, size }));
      } else {
        dispatch(
          showMessage({
            message: "An unknown error occurred",
            variant: "error",
          }),
        );
      }
    } catch (ex) {
      dispatch(
        showMessage({
          message: ex.message ? ex.messge : ex,
          variant: "error",
        }),
      );
    }
  };
  const handleCloseDetails = () => {
    setSelectedIdToView(null);
  };
  const headers = [
    {
      name: "S.No",
    },
    {
      name: "Post Title",
      options: {
        sort: true,
      },
    },
    {
      name: "Comments (for admin user only)",
      options: {
        sort: true,
      },
    },
    {
      name: "Start date (with sort functionality)",
      options: {
        sort: true,
      },
    },
    {
      name: "End date (with sort functionality)",
      options: {
        sort: true,
      },
    },
    {
      name: "Read by (#users, with sort functionality)",
      options: {
        sort: true,
      },
    },
    {
      name: "Actions",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <ActionButtons
              onEdit={handleSelect}
              onViewDetails={handleViewDetails}
              rowData={value}
              onDelete={handleDelete}
              setSelectedId={setSelectedId}
            />
          );
        },
      },
    },
  ];
  const getParsedData = () => {
    return data?.content?.map((m) => {
      return [
        m?.id,
        m.header,
        m.comment,
        new Date(m.validFrom).toLocaleString("en-US"),
        new Date(m.validUntil).toLocaleString("en-US"),
        m.readCount,
        m,
      ];
    });
  };
  const handleSortBy = (e, direction) => {
    const fieldNames = {
      "Start date (with sort functionality)": "validFrom",
      "End date (with sort functionality)": "validUntil",
    };

    const directions = {
      asc: "ASC",
      desc: "DESC",
    };
    setSortBy({
      fieldName: fieldNames[e],
      direction: directions[direction],
    });
  };
  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50],
    onColumnSortChange: (col, direction) => handleSortBy(col, direction),
    onSearchClose: () => {
      dispatch(searchData({ search: "" }));
    },
    customSearchRender: (hideSearch) => {
      return (
        <Search
          classes={classes}
          hideSearch={hideSearch}
          onSearch={searchData}
          placeholder="Search Here..."
        />
      );
    },
    onTableChange: (action, state) => {
      const { pageNo, rowsPerPage } = state;
      switch (action) {
        case "changePage":
          setPageNo(pageNo);
          break;
        case "changeRowsPerPage":
          setPageSize(rowsPerPage);
          break;
      }
    },
  };

  const updateTable = () => {
    dispatch(getAllPosts({ page, size, sortBy }));
  };
  const hideModal = () => {
    //setShowModal(false);
    setShowAddModal(false);
    // setShowHierarchyModal(false);
    // dispatch(resetSelected());
  };
  useEffect(() => {
    updateTable();
  }, [page, size, sortBy]);

  useEffect(() => {
    if (selectedId) {
      setCurrentRecord(data?.content?.find((f) => f.id === selectedId));
    }
  }, [selectedId]);
  return (
    <div className={classes.pageWrapper}>
      <div className={classes.headerContent}>
        <div className={classes.headerOptions}>
          <Box
            display="inline-flex"
            justifyContent="flex-end"
            gap="10px"
            flexWrap="wrap">
            <TableActionButton
              onClick={() => {
                setShowAddModal(true);
                setCurrentRecord(null);
              }}
              title="Add a new Post"
            />
          </Box>
        </div>
      </div>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.mainrapper}>
        <div className={classes.fieldswrapper}>
          <MUIDataTable
            title="Product News"
            columns={headers}
            data={getParsedData()}
            options={{
              ...options,
              count: data?.totalElements,
            }}
            onPageChange={() => {}}
            className={classes.customMaterialDataTable}
          />
        </div>
      </div>
      {showAddModal && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showAddModal}>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>{currentRecord ? "Update" : "Create"} Product News</Box>
              <Box textAlign="right">
                <IconButton onClick={hideModal} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <CreateProductNewsForm
            currentRecord={currentRecord}
            onClose={hideModal}
            updateTable={updateTable}
            setSelectedId={setSelectedId}
          />
        </Dialog>
      )}
      {Boolean(selectedIdToView) && (
        <Dialog
          open={Boolean(selectedIdToView)}
          maxWidth="lg"
          fullWidth
          onClose={handleCloseDetails}
          scroll="body">
          <DialogHeader
            onClose={handleCloseDetails}
            title={"Product News Details"}
          />
          <div className={classes.pageWrapper}>
            <AttributeDetails data={currentRecord} />
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default withMultipleStyles(styles, dataTableStyles)(ProductNews);
