import { createSlice } from "@reduxjs/toolkit";
import { productNewsService } from "../../services/productNewsService";
import { showMessage } from "../Revalu/notificationSlice";

export const getAllPosts = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return productNewsService
    .getAllPosts(data)
    .then((res) => {
      dispatch(setProductNewsData(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const create = (data) => async () => {
  return productNewsService
    .create(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const update = (id, data) => async () => {
  return productNewsService
    .update(id, data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const deletePost = (id) => async (dispatch) => {
  return productNewsService
    .deletePost(id)
    .then((res) => {
      if (Boolean(res?.deletedCount)) {
        dispatch(removeRecord(id));
      }
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

const initialState = {
  data: {
    content: [],
    totalPages: 0,
    totalElements: 0,
    selected: null,
  },
  originalData: {
    content: [],
    totalPages: 0,
    totalElements: 0,
  },
  loading: false,
  searchValue: "",
  elementsData: "",
  selected: null,
};

const productNewsSlice = createSlice({
  name: "mappedEPD/listing",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setProductNewsData: (state, action) => {
      state.data = action.payload;
      state.originalData = action.payload;
    },
    removeRecord: (state, action) => {
      const id = action.payload;
      const index = state.data.content.findIndex((f) => f["id"] === id);
      if (index > -1) {
        state.data.content.splice(index, 1);
        state.originalData.content.splice(index, 1);
      }
    },
    setSelected: (state, action) => {
      const { id } = action.payload;
      const index = state.data.content.findIndex(
        (f) => f.id.toString() === id.toString(),
      );
      if (index > -1) {
        state.selected = state.data.content[index];
      }
    },
    resetSelected: (state, action) => {
      state.selected = null;
    },
  },
});

export const {
  setLoading,
  setProductNewsData,
  //   modifyRecord,
  //   setExportLoading,
  //   setExportProcessStatus,
  //   setExportProcessLoading,
  //   searchData,
  //   setElements,
  //   setBroaderCategories,
  //   setSubCategory,
  //   removeRecord,
  setSelected,
  resetSelected,
} = productNewsSlice.actions;

export default productNewsSlice.reducer;
